import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import LandingHeader from '../LandingPage/LandingHeader';
import Footer from '../Footer/Footer';

const SignupForm = () => {
  const [formData, setFormData] = useState({
    uname: '',
    email: '',
    password: '',
    confirmPassword: '',
    mobileNumber: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

 const Navigate = useNavigate()
 

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const { uname, email, password, confirmPassword, mobileNumber } = formData;
  
    if (password !== confirmPassword) {
      alert("Passwords don't match!");
      return;
    }
  
    try {
      const newUser = {
        username: uname,
        email,
        password,
        mobile: mobileNumber
      };
  
      // Make POST request to backend endpoint
      const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/signup', newUser);
  
      // Check response status and handle accordingly
      if (response.status === 200) {
        alert(response.data.message); // Assuming backend sends back { message: 'User registered successfully...' }
        Navigate('/otp-verification')
        // Reset form data after successful registration
        setFormData({
          uname: '',
          email: '',
          password: '',
          confirmPassword: '',
          mobileNumber: ''
        });
      } else {
        alert('Failed to register user. Please try again.'); // Handle non-200 status codes
      }
    } catch (error) {
      // Handle error scenarios
      if (error.response) {
        console.error('Response error:', error.response); // Log detailed response error
        alert(error.response.data.error); // Display backend error message if available
      } else if (error.request) {
        console.error('Request error:', error.request); // Log request error
        alert('Failed to send request to server.'); // Notify user about request failure
      } else {
        console.error('Error:', error.message); // Log other errors
        alert('Something went wrong. Please try again.'); // Generic error message
      }
    }
  };
  
console.log(formData)
  return (
    <>
    <LandingHeader/>
    {/* <div >
      <div className='p-4 d-flex justify-content-center align-items-center 'style={{fontFamily: 'Arial, sans-serif'}}>
        <div className='p-4  col-lg-3' style={{ height: "725px",marginLeft: "20px" }}>
          <form onSubmit={handleSubmit}>
            <h3 className='text-center mb-4'>Create account</h3>
            <div className='mb-3'>
              <label htmlFor='uname'>User Name</label>
              <input type='text' placeholder='Enter User Name' className='form-control' id='uname' name='uname' value={formData.uname} onChange={handleInputChange} required />
            </div>
            <div className='mb-3'>
              <label htmlFor='email'>Email</label>
              <input type='email' placeholder='Enter Email' className='form-control' id='email' name='email' value={formData.email} onChange={handleInputChange} required />
            </div>
            <div className='mb-3'>
              <label htmlFor='mobileNumber'>Mobile Number</label>
              <input type='number' placeholder='Enter Mobile Number' className='form-control' id='mobileNumber' name='mobileNumber' value={formData.mobileNumber} onChange={handleInputChange} required />
            </div>
            <div className='mb-3'>
              <label htmlFor='password'>Password</label>
              <input type='password' placeholder='Enter Password' className='form-control' id='password' name='password' value={formData.password} onChange={handleInputChange} required />
            </div>
            <div className='mb-3'>
              <label htmlFor='confirmPassword'>Confirm Password</label>
              <input type='password' placeholder='Confirm Password' className='form-control' id='confirmPassword' name='confirmPassword' value={formData.confirmPassword} onChange={handleInputChange} required />
            </div>
            <div className='d-grid gap-2 text-center' >
              <button className='btn btn-danger'>Create</button>
            </div>
            <p className='text-center mt-3'>
              Already Registered? 
              <Link to='/login' className='ms-3' style={{textDecoration:'none'}}> <button className='btn btn-primary btn-sm'>Login</button></Link>
            </p>
          </form>
        </div>
      </div>  
    </div> */}
     <Container className="d-flex justify-content-center align-items-center" style={{fontFamily: 'Arial, sans-serif'}}>
      <Row className="justify-content-center">
        <Col  className="p-4">
          <Form onSubmit={handleSubmit}>
            <h3 className="text-center mb-4">Create account</h3>
            <div className='mb-3'>
                <input
                  type='text'
                  placeholder='User Name'
                  style={{border:'1px solid black',padding:'10px 100px'}}
                  value={formData.uname}
                  onChange={handleInputChange}
                  required                 
                />
              </div>
             <div className='mb-3'>
                <input
                  type='text'
                  placeholder='Email'
                  style={{border:'1px solid black',padding:'10px 100px'}}
                  value={formData.email}
                  onChange={handleInputChange}
                  required                 
                />
              </div>
              <div className='mb-3'>
                <input
                  type='number'
                  placeholder='Mobile Number'
                  style={{border:'1px solid black',padding:'10px 100px'}}
                  value={formData.mobileNumber}
                  onChange={handleInputChange}
                  required                 
                />
              </div>
              <div className='mb-3'>
                <input
                  type='password'
                  placeholder='Enter Password'
                  style={{border:'1px solid black',padding:'10px 100px'}}
                  value={formData.password}
                  onChange={handleInputChange}
                  required                 
                />
              </div>
              <div className='mb-3'>
                <input
                  type='password'
                  placeholder='Confirm Password'
                  style={{border:'1px solid black',padding:'10px 100px'}}
                  value={formData.confirmPassword}
                  onChange={handleInputChange}
                  required                 
                />
              </div>
            <div className="d-grid gap-2 text-center">
              <Button variant="dark" type="submit">Create</Button>
            </div>
            <p className="text-center mt-3">
              Already Registered? 
              <Link to="/login" className="ms-3" style={{ textDecoration: 'none' }}>
                <Button variant="dark" size="sm">Login</Button>
              </Link>
            </p>
          </Form>
        </Col>
      </Row>
    </Container>
    <Footer/>
    </>
  );
};

export default SignupForm;



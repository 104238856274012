import React, { useState } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import A1 from '../Footer/pay.png';
import footer from '../Footer/footer.css';
import { FaFacebookF } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa6";
import { FaWhatsapp, FaYoutube } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";
import Logor from '../images/Logop.png';
function Footer() {


    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const toggleModal = (content) => {
        setModalContent(content);
        setShowModal(!showModal);
    };

    const navgateWebsite = () => {
        window.location.herf = "https://prettywomen.in"
    }


    return (
        <footer className="card footer_black" style={{ fontFamily: 'Arial, sans-serif' }}>
            <div class="container">
                <div>
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-8">
                            <div class="widgets_container">
                                <img src={Logor} style={{ height: '90px', width: '250px', borderRadius: '20px' }} alt="Logo" />
                                <div class="footer_contact mt-3" style={{ fontSize: '14px' }}>
                                    <p>Address : M/S SWEETY JEWELS PRIVATE LIMITED
                                        3/4/346/1, TOBACCO BAZAR, SECUNDERABAD, Hyderabad,
                                        Telangana, 500003</p>
                                    <p>Phone : <a href="tel:(+91)8008156792">(+91)9059024319</a></p>
                                    <p>Email : info@prettywomen.in</p>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 col-sm-4 col-6">
                            <div class="widgets_container widget_menu">
                                <h3>Our Products</h3>
                                <div class="footer_menu">
                                    <ul>
                                        <li><a href="/trend" style={{ textDecoration: 'none' }}>Trending</a></li>
                                        <li><a href="/brace#" style={{ textDecoration: 'none' }}>Bracelets</a></li>
                                        <li><a href="/rings" style={{ textDecoration: 'none' }}>Rings</a></li>
                                        <li><a href="/necklaces" style={{ textDecoration: 'none' }}>Necklaces</a></li>
                                        <li><a href="/earrings" style={{ textDecoration: 'none' }}>Earrings</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-5 col-6">
                            <div class="widgets_container widget_menu">
                                <h3>Custmer Care</h3>
                                <div class="footer_menu">
                                    <ul>
                                        <li><a href="#" style={{ textDecoration: 'none' }}>FAQs</a></li>
                                        <li><a href="/refund" style={{ textDecoration: 'none' }}>Refund / Cancelation Policy</a></li>
                                        <li><a href="/return" style={{ textDecoration: 'none' }}>Return Policy</a></li>
                                        <li><a href="/termsconditions" style={{ textDecoration: 'none' }}>Terms & Conditions</a></li>
                                        <li><a href="/privacypolicy" style={{ textDecoration: 'none' }}>Privacy Policy</a></li>
                                        <li><a href="/shippingpolicy" style={{ textDecoration: 'none' }}>Shipping Policy</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-7">
                            <div class="widgets_container product_widget">
                                <h3>Our Company</h3>
                                <div class="footer_menu">
                                    <ul>
                                        <li><a href="#" style={{ textDecoration: 'none' }}>about</a></li>
                                        <li><a href="#" style={{ textDecoration: 'none' }}>Lookbook</a></li>                                       
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    {/* <div className="col">
                        <p>Subscribe to our emails</p>
                        <div className="subscribe-form">
                            <input
                                type="email"
                                placeholder="Enter your email"
                                style={{ border: '1px solid black', padding: '10px 80px' }}
                                className="email-input"
                                aria-label="Email address"
                            />

                        </div>
                    </div> */}
                    <div className="col footer_contact text-end">
                        <ul>
                            <li><a href="https://www.facebook.com/prettywomenaccessories/" target="blank"><i><FaFacebookF /></i></a></li>
                            <li><a href="https://www.instagram.com/prettywomen_accessories?igsh=MXc0bG14dHI4bXRuaw==" target="blank"><i><FaInstagram /></i></a></li>
                            <li><a href="https://wa.me/+919059024319" target="blank"><i><FaWhatsapp /></i></a></li>
                            <li><a href="#"><i><FaTwitter /></i></a></li>
                            <li><a href="#"><i><FaYoutube /></i></a></li>
                            <li><a href="#"><i><FaGoogle /></i></a></li>
                        </ul>
                    </div>

                </div>

                <div class="footer_bottom">
                    <div class="row">
                        <div class="col-12">
                            <div class="copyright_area ">
                                <p>Copyright &copy; 2024 <a href="#">eMadhaTechnologies </a> All rights Reserved.</p>
                                <img src={A1} alt="" style={{height:'50px',width:''}}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </footer>
    );
};
export default Footer;

import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { Link } from 'react-router-dom';
import { BsLayoutTextSidebar } from "react-icons/bs";
import Logo1 from '../Pages/Logo1.png';
import { FaHome, FaProductHunt, FaUserFriends, FaUser } from "react-icons/fa";
import { BiAnalyse, BiCog } from "react-icons/bi";
import { MdOutlineShoppingCartCheckout, MdAccountBalanceWallet } from "react-icons/md";


const Sidebar = () => {
  const [stockOpen, setStockOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [userType, setUserType] = useState('');

  useEffect(() => {
    const type = sessionStorage.getItem('userType');
    setUserType(type);
  }, []);

  const toggleStock = () => {
    setStockOpen(!stockOpen);
  };

  const toggleNav = () => {
    setIsOpen(!isOpen);
    if (window.innerWidth <= 768) {
      document.getElementById("mySidebar").classList.toggle("open");
    }
  };

  const usertypes=sessionStorage.getItem('userType') 
  console.log(usertypes)
  
  return (
    <div id='main'>
      <div id="mySidebar" className={`sidebar ${isOpen ? 'open' : 'closed'}`} >
        <div className="sidebar-header">
          <img src={Logo1} style={{ height: '95px', width: '120px', marginLeft: '60px' }} alt="Logo1" />
          <button className="toggle-btn" onClick={toggleNav}></button>
        </div>
        <ul className="sidebar-menu" style={{ fontFamily: 'Arial, sans-serif' }}>
          {usertypes === 'admin' && (
            <>
              <Link to="/dashboard"><FaHome className='me-2' />Dashboard</Link>
              <Link to="/productMaster"><FaProductHunt className='me-2' />Product Master</Link>
              <>
                <Link onClick={toggleStock} className="dropdown-toggle">
                  <MdOutlineShoppingCartCheckout className='me-2' />Stock
                </Link>
                {stockOpen && (
                  <ul>
                    <li><Link to="/stockAdd">Stock Add</Link></li>
                    <li><Link to="/stockChecking">Stock Status</Link></li>
                  </ul>
                )}
              </>
              <Link to="/logistics"><BiAnalyse className='me-2' />Logistics</Link>
              <Link to="/user"><FaUser className='me-2' />User</Link>
              <Link to="/account"><MdAccountBalanceWallet className='me-2' />Accountant</Link>
              <Link to="/customers"><FaUserFriends className='me-2' />Customers</Link>
            </>
          )}
          {usertypes === 'StoctAdd' && (
            <>
              <Link to="/dashboard"><FaHome className='me-2' />Dashboard</Link>
              <Link to="/productMaster"><FaProductHunt className='me-2' />Product Master</Link>
              <>
                <Link onClick={toggleStock} className="dropdown-toggle">
                  <MdOutlineShoppingCartCheckout className='me-2' />Stock
                </Link>
                {stockOpen && (
                  <ul>
                    <li><Link to="/stockAdd">Stock Add</Link></li>
                    <li><Link to="/stockChecking">Stock Status</Link></li>
                  </ul>
                )}
              </>
            </>
          )}
          {usertypes === 'account' && (
            <>
              <Link to="/acdashboard"><FaHome className='me-2' />Dashboard</Link>
              <Link to="/account"><MdAccountBalanceWallet className='me-2' />Accountant</Link>
            </>
          )}
          {usertypes === 'logistics' && (
            <>
              <Link to="/lodashboard"><FaHome className='me-2' />Dashboard</Link>
              <Link to="/logistics"><BiAnalyse className='me-2' />Logistics</Link>           
            </>
          )}
          
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;




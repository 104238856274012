
// PaymentStatus.js
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Footer from '../Footer/Footer';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import '../Checkout/paymentstatus.css'; // Import custom CSS for animation
import LandingHeader from '../LandingPage/LandingHeader';
import { clearCart } from '../../Redux/cartActions';

const PaymentStatus = () => {
  const [paymentId, setPaymentId] = useState(null);
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isSuccessful, setIsSuccessful] = useState(null);
  const [userId, setUserId] = useState(null);
  const [productId, setProductId] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    // Extract paymentId and token from the URL path
    const url = window.location.pathname;
    const segments = url.split('/');
     // Assuming the URL format is: /pretty/pg/v1/payment-status/:trid/:mt/:userId
     const trid = segments[segments.length - 4]; // Transaction ID
     const mt = segments[segments.length - 3];  // Token
     const userId = segments[segments.length - 2]; // User ID
     const pId = segments[segments.length - 1]; // Product ID

     
    // console.log(trid)
    // console.log(mt)
    // console.log(userId)
    // console.log(pId)

    setPaymentId(trid);
    setToken(mt);
    setUserId(userId);
    setProductId(pId);

    async function fetchStatus() {
      try {
        const response = await fetch(`https://sjpapi.prettywomen.in/API/v1/pretty/pg/v1/payment-status/${trid}/${mt}/${userId}/${pId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            trid: trid,
            mt: mt,
            userId: userId,
            pId: pId
          }),
        });

        const result = await response.text();
        setStatus(result);

        // Determine if payment was successful
        if (result.includes('Payment Successful')) {
          setIsSuccessful(true);
          dispatch(clearCart()); // Clear the cart if payment is successful
        } else {
          setIsSuccessful(false);
        }

      } catch (error) {
        console.error('Error fetching payment status:', error);
        setStatus('Error fetching payment status');
        setIsSuccessful(false);
      } finally {
        setLoading(false);
      }
    }

    fetchStatus();
  }, [dispatch]);


  if (loading) {
    return <div>Loading...</div>;
  }



  return (
    <>
      <LandingHeader />
      <div className="container mt-4">
        <div className="card-status">
        <p className="text-center"><strong>Transaction ID:</strong> {paymentId}</p> {/* Display the transaction ID */}
        <div className="text-center" dangerouslySetInnerHTML={{ __html: status }}></div>
          {isSuccessful === true && (
            <div className="card-io card-success">
              <div className="card-body">
                <FaCheckCircle className="icon-success" />
                <h5 className="card-title">Payment Successful</h5>
                <p className="card-text">Transaction was completed successfully.</p>
              </div>
            </div>
          )}
          {isSuccessful === false && (
            <div className="card card-failure">
              <div className="card-body">
                <FaTimesCircle className="icon-failure" />
                <h5 className="card-title">Payment Failed</h5>
                <p className="card-text">There was an issue with the transaction.</p>
              </div>
            </div>
          )}
           {isSuccessful === null && (
            <div className="card card-info">
              <div className="card-body">
                <h5 className="card-title">Status</h5>
                <p className="card-text">{status}</p> {/* Display the status text */}
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default PaymentStatus;


import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaRupeeSign } from "react-icons/fa";
import { CiHeart } from "react-icons/ci";
import { addToWishlist } from '../../Redux/wishlistSlice';
import { Button, Col } from 'react-bootstrap';
import './cart.css';

const ProductCart = (props) => {
  const [timeRemaining, setTimeRemaining] = useState('');
  const [offerExpired, setOfferExpired] = useState(false);
  const carts = useSelector(store => store.cart.items);
  const { id, pname, pprice, pimage, discount, pcolor, validity, quantity } = props.data;
  const dispatch = useDispatch();

  const handleAddToWishlist = () => {
    dispatch(addToWishlist({
      productId: id,
      quantity: 1,
      color: pcolor,
      name: pname,
      price: pprice,
      image: pimage,
      discount: discount
    }));
  };

  const calculateTimeRemaining = () => {
    const endTime = new Date(validity).getTime();
    const now = new Date().getTime();
    const distance = endTime - now;

    if (distance <= 0) {
      setTimeRemaining('Offer Expired');
      setOfferExpired(true);
    } else {
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      setTimeRemaining(`${days}d ${hours}h ${minutes}m ${seconds}s`);
      setOfferExpired(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      calculateTimeRemaining();
    }, 1000);

    return () => clearInterval(interval);
  }, []);



  return (
    <Col className=' my-3' style={{fontFamily:'Arial, sans-serif',fontSize:'10px'}}>
      <div className='shadow d-flex flex-column'>
        <img src={pimage} alt={pname} className='img-fluid img-hover' style={{height: '200px',borderRadius:'10px'}} />
        <div className='p-2'>
          <div className="d-flex align-items-center">
            <h6 className='me-5' style={{ fontSize: '15px' }}>{pname}</h6>
            <div className='ms-auto'>
              <Link to='/wishlist'>
                <button className='btn' onClick={handleAddToWishlist}>
                  <CiHeart style={{ color: 'red', fontSize: '20px' }} />
                </button>
              </Link>
            </div>
          </div>   
          <p className='mb-0'>Color: {pcolor}</p>
          <div className='d-flex align-items-center mb-0'>
            {!offerExpired && discount ? (
              <span className='text-success'>
                <FaRupeeSign />
                {Math.round(pprice - (pprice * discount / 100))}
              </span>
            ) : (
              <span className='text-success'>
                <FaRupeeSign />
                {Math.round(pprice)}
              </span>
            )}
            <span className={`ms-2 ${offerExpired ? 'text-muted' : 'text-decoration-line-through'}`}>
              <FaRupeeSign /> {Math.round(pprice)}
            </span>
            {!offerExpired && discount && <span className='ms-2 text-danger'>({discount}% Off)</span>}
          </div>
          <p className='text-danger mb-0'>{timeRemaining}</p>
          <p className='text-black mb-0'>Available: {quantity}</p>
          <div className='d-flex justify-content-end'>           
            <Link to={`/productdetails/${id}`}>
              <Button variant='dark' style={{ padding: '1px 10px' }} className='btn-sm'>
                More
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default ProductCart;



import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';
import { Container, Row, Col, Button, Table } from 'react-bootstrap'; // Import Table from react-bootstrap
import axios from 'axios';
import LandingHeader from '../LandingPage/LandingHeader';

const CheckoutPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/cart');
        setCartItems(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCartItems();
  }, []);


  const handleProceedToPayment = async () => {
    try {
      const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/api/cart-items', {
        cartItems,
        totalPrice
      }, {
        headers: { 'Content-Type': 'application/json' }
      });
      
      if (response.status === 200) {
        navigate('/paymentpage');
      }
    } catch (err) {
      console.error('Error during checkout:', err);
      setError('An error occurred while processing your payment.');
    }
  };

  // Calculate total price
  const totalPrice = cartItems.reduce((total, cartItem) => {
    return total + (cartItem.pprice - (cartItem.pprice * cartItem.discount) / 100) * cartItem.quantity;
  }, 0);


  

  return (
    <div>
      <LandingHeader />
      <Container className="my-4" style={{ fontFamily: 'Nexa, sans-serif', fontSize: '13px' }}>
        <h1 className='text-center'style={{color:' #d47bb1'}}><strong>CHECKOUT</strong></h1>
        <Row className="justify-content-center mt-5">
          <Col md={8}>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Quantity</th>
                  <th>MRP</th>
                  <th>Offer</th>
                  <th>Offer Price</th>
                 
                </tr>
              </thead>
              <tbody>
                {cartItems.map(cartItem => (
                  <tr key={cartItem.productId}>
                    <td><img src={cartItem.pimage} alt={cartItem.pname} style={{ width: '100px', height: 'auto' }} /></td>                   
                    <td>{cartItem.pname}</td>
                    <td>{cartItem.quantity}</td>
                    <td>{cartItem.pprice}</td>
                    <td>{cartItem.discount}%</td>
                    <td>Rs. {Math.round(cartItem.pprice - (cartItem.pprice * cartItem.discount) / 100)}</td>
                 
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="bg-light p-4 rounded mt-4">
              <h6>Total Products: {cartItems.length}</h6>
              <hr />
              <h6>Total Price: Rs. {Math.round(totalPrice)}</h6>
            </div>
            <div className="text-center">
        
                <Button variant="dark" onClick={handleProceedToPayment}style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>Proceed to Payment</Button>
         
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />
    </div>
  );
};

export default CheckoutPage;


// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import axios from 'axios';
// import CartItem from './cartItem';
// import Footer from '../Footer/Footer';
// import { Link } from 'react-router-dom';
// import { clearCart } from '../../Redux/cartActions';
// import LandingHeader from '../LandingPage/LandingHeader';


// const CartTab = () => {
//   const [productList, setProductList] = useState([]);
//   const [cartItems, setCartItems] = useState([]);
//   const [alert, setAlert] = useState({ message: '', type: '' });
//   const dispatch = useDispatch();
//   const statusTab = useSelector(store => store.cart.statusTab);



//     const fetchProducts = async () => {
//       try {
//         const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
//         setProductList(response.data);
//       } catch (err) {
//         setAlert({ message: 'Failed to fetch products. Please try again later.', type: 'error' });
//         console.error('Failed to fetch products:', err);
//       }
//     };

//     const fetchCartItems = async () => {
//       try {
//         const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/cart'); // Adjust this URL if necessary
//         setCartItems(response.data);
//       } catch (err) {
//         setAlert({ message: 'Failed to fetch cart items. Please try again later.', type: 'error' });
//         console.error('Failed to fetch cart items:', err);
//       }
//     };
   

//   useEffect(() => {

//     fetchCartItems();
//     fetchProducts();
//   }, []);

//   // console.log(cartItems)

//   const cartItemsWithProductData = cartItems.map(cartItem => ({
//     ...cartItem,
//     product: productList.find(product => product.id === cartItem.productId)
//   }));

//   const handleClearCart = () => {
//     dispatch(clearCart());
//     setCartItems([]); // Clear local cart items
//   };

//   return (

//     <div>
//       <LandingHeader />
//       <div className={statusTab === false ? "translate-x-full" : ""} style={{fontFamily:'Arial, sans-serif',fontSize:'13px'}}>
//         <h1 className='text-center mt-5'style={{color:' #d47bb1'}}><strong>SHOPPING CART</strong></h1>     
//         <div className='container-fluid mt-5'>
//           <div className='row bg-light justify-content-center'>
//             {cartItems.length > 0 ? (
//               cartItems.map((item) => (
//                 <CartItem key={item.id} data={item} />
//               ))
//             ) : (
//               <div className='text-center'>Your cart is empty</div>
//             )}

//           </div>
//         </div>
//         <div className='container grid grid-cols-2 text-center mt-5'>
//           <Link to="/checkoutpage">
//             <button className='btn btn-dark btn-sm mb-2'style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>CHECKOUT</button>
//           </Link>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }

// export default CartTab;


// CartTab.js

import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import CartItem from './cartItem';
import Footer from '../Footer/Footer';
import { Link } from 'react-router-dom';
import { clearCart } from '../../Redux/cartActions';
import LandingHeader from '../LandingPage/LandingHeader';

const CartTab = () => {
  const [productList, setProductList] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [alert, setAlert] = useState({ message: '', type: '' });
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart.cartItems);

  const fetchProducts = async () => {
    try {
      const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
      setProductList(response.data);
    } catch (err) {
      setAlert({ message: 'Failed to fetch products. Please try again later.', type: 'error' });
      console.error('Failed to fetch products:', err);
    }
  };

  const fetchCartItems = async () => {
    try {
      const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/cart');
      setCartItems(response.data);
    } catch (err) {
      setAlert({ message: 'Failed to fetch cart items. Please try again later.', type: 'error' });
      console.error('Failed to fetch cart items:', err);
    }
  };

  useEffect(() => {
    fetchCartItems();
    fetchProducts();
  }, []);

  useEffect(() => {
    setCartItems(cart);
  }, [cart]);

  const cartItemsWithProductData = cartItems.map(cartItem => ({
    ...cartItem,
    product: productList.find(product => product.id === cartItem.productId),
  }));

  const handleClearCart = () => {
    dispatch(clearCart());
    setCartItems([]);
  };

  return (
    <div>
      <LandingHeader />
      <div className="container mt-5">
        <h1 className='text-center mt-5' style={{ color: '#d47bb1' }}><strong>SHOPPING CART</strong></h1>
        <div className='container-fluid mt-5'>
          <div className='row bg-light justify-content-center'>
            {cartItems.length > 0 ? (
              cartItemsWithProductData.map((item) => (
                <CartItem key={item.id} data={item} />
              ))
            ) : (
              <div className='text-center'>Your cart is empty</div>
            )}
          </div>
        </div>
        <div className='container grid grid-cols-2 text-center mt-5'>
          <Link to="/checkoutpage">
            <button className='btn btn-dark btn-sm mb-2' style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}>CHECKOUT</button>
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CartTab;

import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import LandingHeader from '../LandingPage/LandingHeader';
import Footer from './Footer';

const ShippingPolicy = () => {
  return (
    <>
    <LandingHeader/>
    <div className="container mt-4" style={{fontFamily:'Arial, sans-serif',fontSize:'13px'}}>
      <div className="row">
        <div className="col-md-12">
          <h2 className="mb-4 text-center"style={{fontSize:'50px'}}>Shipping Policy</h2>
          <p>
            The orders for the user are shipped through registered domestic courier companies and/or speed post only. 
            Orders are shipped within <strong>2 days</strong> from the date of the order and/or payment or as per 
            the delivery date agreed at the time of order confirmation and delivering of the shipment, subject to 
            courier company/post office norms. Platform Owner shall not be liable for any delay in delivery by the 
            courier company/postal authority. Delivery of all orders will be made to the address provided by the buyer 
            at the time of purchase. Delivery of our services will be confirmed on your email ID as specified at the 
            time of registration. If there are any shipping cost(s) levied by the seller or the Platform Owner (as 
            the case be), the same is not refundable.
          </p>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
}

export default ShippingPolicy;

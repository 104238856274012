
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import axios from 'axios';

const logitems = [
  { id: 1, name: "Trending" },           
  { id: 2, name: 'Bracelets' },       
  { id: 3, name: 'Earrings' },
  { id: 4, name: 'Necklaces' },
  { id: 5, name: 'Rings' },
  { id: 5, name: 'Anklet' },
];

const ProductMaster = () => {
  const [formData, setFormData] = useState({
    newCategory: '',
    newSubcategory: ''
  });

  const [categoriesList, setCategoriesList] = useState([]);

 
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/ap/subcategories');
        setCategoriesList(response.data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    useEffect(() => {

    fetchCategories();
    
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleAdd = async () => {
    try {
      const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/ap/categories', {
        name: formData.newCategory,
        subCategory: formData.newSubcategory
      });
      console.log('Category added:', response.data);
     
      setCategoriesList(prevCategories => [
        ...prevCategories,
        { id: response.data.id, name: formData.newCategory, subCategory: formData.newSubcategory }
      ]);

      // Optionally reset form data or update UI
      setFormData({
        newCategory: '',
        newSubcategory: ''
      });
    } catch (error) {
      console.error('Error adding category:', error);
    }
  };
  
 
  const handleDeleteCategory = (id) => {
    axios.delete(`https://sjpapi.prettywomen.in/API/v1/api/categories/${id}`)
      .then(response => {
        setCategoriesList(prevCategories => prevCategories.filter(category => category.id !== id));
        // localStorage.setItem('categoriesList', JSON.stringify(categoriesList.filter(category => category.name !== name)));
      })
      .catch(error => {
        console.error('Error deleting category:', error);
      });
  };

  console.log(categoriesList)


  return (
    <div>
      <div className="d-flex">
        <Sidebar />
        <div className="w-100">
          <Header />
          <div className='container text-center mt-2' style={{fontFamily: 'Arial, sans-serif',fontSize:'13px'}}>
            <h4>ProductMaster</h4>
         
         
            <div className="card-body ">
              <input type="text" className="form-control mb-2" placeholder="Enter category" name="newCategory" value={formData.newCategory} onChange={handleChange} />
              <div className="mb-4">
                <div className="d-flex align-items-center mb-2">
                  <select className="form-select me-2" value={formData.newSubcategory} onChange={handleChange} name="newSubcategory">
                    <option value="">Select subcategory</option>
                    {logitems.map((item) => (
                      <option key={item.id} value={item.name}>{item.name}</option>
                    ))}
                  </select>
                  <input type="text" className="form-control" placeholder="Or other subcategory" value={formData.newSubcategory} onChange={handleChange} name="newSubcategory" />
                </div>
                <Button className='btn btn-success btn-sm mb-2' onClick={handleAdd}>
                  {formData.newSubcategory ? 'Add Subcategory' : 'Add Category'}
                </Button>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Category</th>
                      <th scope="col">Sub Category</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoriesList.map(category => (
                      <tr key={category.id}>
                        <td>{category.name}</td>  
                        <td>{category.subCategory}</td>
                                          
                        <td>
                          <Button onClick={() => handleDeleteCategory(category.id)} style={{ padding: '1px 10px' }} className='btn-sm'>Delete</Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
 
    </div>
  );
};

export default ProductMaster;



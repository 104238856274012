import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import { Table, Container } from 'react-bootstrap';
import axios from 'axios';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState(null);

  // Retrieve the email from local storage or other source
  const userEmail = localStorage.getItem('emailUser'); // Adjust as needed

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        if (!userEmail) {
          setError('No email provided');
          return;
        }

        // Make sure to encode the email address to handle special characters
        const response = await axios.get(`https://sjpapi.prettywomen.in/API/v1/api/users?email=${encodeURIComponent(userEmail)}`);

        // Handle cases where the API returns an empty array or no data
        if (response.data && Array.isArray(response.data)) {
          setCustomers(response.data);
        } else {
          setError('No customers found');
        }
      } catch (err) {
        console.error('Error fetching customer data:', err);
        setError('Failed to fetch customer data');
      }
    };

    fetchCustomers();
  }, [userEmail]);

  if (error) return <div>{error}</div>;

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="w-100">
        <Header />
        <Container className="my-4">
          <h5>Customers Details</h5>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Username</th>
                <th>Mobile Number</th>
                <th>Email ID</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {customers.length > 0 ? (
                customers.map((customer) => (
                  <tr key={customer.id}>
                    <td>{customer.username}</td>
                    <td>{customer.mobile}</td>
                    <td>{customer.email}</td>
                    <td>{customer.Address || 'N/A'}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">No customer data available</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Container>
      </div>
    </div>
  );
};

export default Customers;

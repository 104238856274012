
import { ADD_TO_CART, CHANGE_QUANTITY, REMOVE_FROM_CART, CLEAR_CART } from './cartActionTypes';

const initialState = {
  cartItems: JSON.parse(localStorage.getItem('cartItems')) || [],
  totalQuantity: JSON.parse(localStorage.getItem('totalQuantity')) || 0, // Added totalQuantity
};

const calculateTotalQuantity = (items) => {
  return items.reduce((total, item) => total + item.quantity, 0);
};

const cartReducer = (state = initialState, action) => {
  let updatedCartItems;
  let updatedTotalQuantity;

  switch (action.type) {
    case ADD_TO_CART:
      const existingCartItemIndex = state.cartItems.findIndex(item => item.productId === action.payload.productId);

      if (existingCartItemIndex !== -1) {
        updatedCartItems = state.cartItems.map((item, index) =>
          index === existingCartItemIndex
            ? { ...item, quantity: item.quantity + action.payload.quantity }
            : item
        );
      } else {
        updatedCartItems = [...state.cartItems, action.payload];
      }
      break;
    case CHANGE_QUANTITY:
      updatedCartItems = state.cartItems.map(item =>
        item.productId === action.payload.productId
          ? { ...item, quantity: action.payload.quantity }
          : item
      );
      break;
    case REMOVE_FROM_CART:
      updatedCartItems = state.cartItems.filter(item => item.productId !== action.payload);
      updatedCartItems = [];
      break;
    case CLEAR_CART:
      updatedCartItems = [];
      break;
    default:
      return state;
  }

  updatedTotalQuantity = calculateTotalQuantity(updatedCartItems);
  localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
  localStorage.setItem('totalQuantity', JSON.stringify(updatedTotalQuantity)); // Store total quantity
  
  return {
    ...state,
    cartItems: updatedCartItems,
    totalQuantity: updatedTotalQuantity, // Update cart count in state
  };
};

export default cartReducer;


import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import axios from 'axios';

const Logistics = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [dispatchDetails, setDispatchDetails] = useState('');
  const [dispatchDate, setDispatchDate] = useState('');
  const [trackingId, setTrackingId] = useState('');
  const [addresses, setAddresses] = useState({ billingAddress: {} });
  const [orderStatuses, setOrderStatuses] = useState({});
  const [dispatchInfo, setDispatchInfo] = useState(null);

  const formRef = useRef(null);

  // Fetch orders and their statuses on page load
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersResponse = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/transactions/details');
        const ordersData = ordersResponse.data;
        console.log('Orders Response:', ordersData);

        // Initialize order statuses
        const statuses = {};

        // Fetch dispatch details for each order to determine the status
        await Promise.all(ordersData.map(async (order) => {
          try {
            const dispatchResponse = await axios.get(`https://sjpapi.prettywomen.in/API/v1/get-dispatch/${order.orderId}`);
            const dispatchData = dispatchResponse.data;

            statuses[order.orderId] = {
              status: dispatchData.flag === 1 ? 'success' : 'pending',
              trackingId: dispatchData.trackingId
            };
          } catch (error) {
            console.error(`Error fetching dispatch details for order ${order.orderId}:`, error);
            statuses[order.orderId] = { status: 'pending', trackingId: 'N/A' };
          }
        }));

        setOrderStatuses(statuses);
        setOrders(ordersData);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  // Fetch addresses and dispatch details when an order is selected
  useEffect(() => {
    if (selectedOrder) {
      const fetchAddresses = async () => {
        try {
          const response = await axios.get(`https://sjpapi.prettywomen.in/API/v1/api/get-addresses/${selectedOrder.userId}`);
          setAddresses(response.data);
        } catch (error) {
          console.error('Error fetching addresses:', error);
        }
      };

      const fetchDispatchDetails = async () => {
        try {
          const response = await axios.get(`https://sjpapi.prettywomen.in/API/v1/get-dispatch/${selectedOrder.orderId}`);
          const dispatchData = response.data;
          console.log('Dispatch Details Response:', dispatchData);

          setDispatchInfo(dispatchData);
          setDispatchDetails(dispatchData.dispatchDetails || '');
          setDispatchDate(dispatchData.dispatchDate || '');
          setTrackingId(dispatchData.trackingId || '');

        } catch (error) {
          console.error('Error fetching dispatch details:', error);
          setDispatchInfo(null);
          setDispatchDetails('');
          setDispatchDate('');
          setTrackingId('');
        }
      };

      fetchAddresses();
      fetchDispatchDetails();
    }
  }, [selectedOrder]);

  // Handle order click
  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    setShowModal(true);
  };

  // Handle form submission
  const handleSubmitDispatchDetails = async (event) => {
    event.preventDefault();

    if (!dispatchDetails || !dispatchDate || !trackingId) {
      alert('Please fill in all required fields.');
      return;
    }

    const orderDetails = {
      orderId: selectedOrder.orderId,
      userEmail: selectedOrder.email,
      dispatchDetails,
      dispatchDate,
      trackingId,
      customerName: selectedOrder.username,
    };

    try {
      const response = await axios.post('https://sjpapi.prettywomen.in/API/v1/dispatch/submit-order', orderDetails, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        const { trackingId, flag } = response.data;

        setOrderStatuses(prevStatuses => {
          const updatedStatuses = { ...prevStatuses };
          updatedStatuses[selectedOrder.orderId] = {
            status: flag === 1 ? 'success' : 'pending',
            trackingId
          };

          return updatedStatuses;
        });

        alert('Order submitted and email sent successfully.');
        window.location.reload();
        setShowModal(false);
        setSelectedOrder(null);
      } else {
        throw new Error('Submission failed');
      }
    } catch (error) {
      console.error('Error submitting order:', error.response ? error.response.data : error.message);
      alert('Error submitting order: ' + (error.response?.data?.error || error.message));
    }
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="w-100">
        <Header />
        <div className='text-center mt-4' style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
          <h4>Logistics</h4>

          {orders.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Order Date:</th>
                  <th>Order ID</th>
                  <th>Tracking ID</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {orders.map(order => (
                  <tr key={order.transactionId}>
                    <td>{new Date(order.transactionCreatedAt).toLocaleDateString()}</td>
                    <td>{order.orderId}</td>
                    <td>
                      {orderStatuses[order.orderId]?.status === 'success' ?
                        orderStatuses[order.orderId].trackingId :
                        'N/A'}
                    </td>
                    <td>
                      <Button
                        variant={orderStatuses[order.orderId]?.status === 'success' ? 'success' : 'danger'}
                        className="btn-sm"
                        onClick={() => handleOrderClick(order)}
                      >
                        {orderStatuses[order.orderId]?.status === 'success' ? 'Success' : 'Pending'}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No orders available.</p>
          )}

          <Modal show={showModal} onHide={() => setShowModal(false)} >
            <Modal.Header closeButton>
              <h4>Order and Dispatch Details</h4>
            </Modal.Header>

            <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
              {selectedOrder && (
                <>
                  <p><strong>Order ID:</strong> {selectedOrder.orderId} </p>
                  <p><strong>Name:</strong> {selectedOrder.username} <strong>Email:</strong> {selectedOrder.email}</p>
                  <p><strong>Mobile:</strong> {selectedOrder.userMobile}</p>
                  <p><strong>Billing Address:</strong></p>
                  <div className="row">
                    <div className="col-lg-6">
                      <div>House No: {addresses.billingAddress.houseNo}</div>
                      <div>Address: {addresses.billingAddress.address}</div>
                      <div>City: {addresses.billingAddress.city}</div>
                      <div>State: {addresses.billingAddress.state}</div>
                      <div>ZIP: {addresses.billingAddress.zip}</div>
                      <div>WhatsApp: {addresses.billingAddress.whatsapp}</div>
                    </div>
                    <div className="col-lg-6">
                      <p><strong>Product Details:</strong></p>
                      <ol>
                        {selectedOrder.productId ? (
                          <div key={selectedOrder.productId}>
                            <img src={selectedOrder.productImage || 'default-image.png'} alt={selectedOrder.productName || 'Product Image'} style={{ width: '80px', height: '80px' }} />
                            <div>Name: {selectedOrder.productName || 'No name provided'}</div>
                            <div>Price: Rs. {selectedOrder.productPrice || '0.00'}</div>
                            <div>Style Code: {selectedOrder.stylecode || 'N/A'}</div>
                          </div>
                        ) : (
                          <div>No product details available.</div>
                        )}
                      </ol>
                    </div>
                  </div>

                  <p className='mb-2'><strong>Order Date:</strong> {new Date(selectedOrder.transactionCreatedAt).toLocaleDateString()}</p>

                  {orderStatuses[selectedOrder.orderId]?.status === 'success' ? (
                    <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
                      <h5>Dispatch Details:</h5>
                      <p><strong>Docket ID:</strong> {dispatchInfo?.trackingId}</p>
                      <p><strong>Dispatch Details:</strong> {dispatchInfo?.dispatchDetails}</p>
                      <p><strong>Dispatch Date:</strong> {dispatchInfo?.dispatchDate}</p>
                    </div>
                  ) : (
                    <div>
                      <h5 className='text-center text-black'>Enter Dispatch Details</h5>
                      <Form ref={formRef} onSubmit={handleSubmitDispatchDetails}>
                        <Form.Group controlId="formOrderId">
                          <Form.Label>Order ID</Form.Label>
                          <Form.Control
                            type="text"
                            value={selectedOrder ? selectedOrder.orderId : ''}
                            readOnly
                          />
                        </Form.Group>
                        <Form.Group controlId="formCustomerEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            value={selectedOrder ? selectedOrder.email : ''}
                            readOnly
                          />
                        </Form.Group>
                        <Form.Group controlId="formTrackingId">
                          <Form.Label>Docket ID</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter tracking ID"
                            value={trackingId}
                            onChange={(e) => setTrackingId(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group controlId="formDispatchDate">
                          <Form.Label>Dispatch Date</Form.Label>
                          <Form.Control
                            type="date"
                            value={dispatchDate}
                            min={new Date().toISOString().split('T')[0]}
                            onChange={(e) => setDispatchDate(e.target.value)}
                          />
                        </Form.Group>
                        <Form.Group controlId="formDispatchDetails">
                          <Form.Label>Dispatch Details</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Enter dispatch details here..."
                            value={dispatchDetails}
                            onChange={(e) => setDispatchDetails(e.target.value)}
                          />
                        </Form.Group>
                        <Button variant="primary btn-sm" type="submit">
                          Submit
                        </Button>
                      </Form>
                    </div>
                  )}
                </>
              )}
            </div>
          </Modal>
          
          


        </div>
      </div>
    </div>
  );
};

export default Logistics;

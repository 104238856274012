import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BsFillArchiveFill, BsFillGrid3X3GapFill, BsPeopleFill,  } from 'react-icons/bs';
import { FiBox } from "react-icons/fi";
import { BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';

const Dashboard = () => {
  const [productCount, setProductCount] = useState(0);
  const [categoryCount, setCategoryCount] = useState(2); 
  const [customerCount, setCustomerCount] = useState(0);
  const [alertCount, setAlertCount] = useState(0);
  const [sidebarOpen, setSidebarOpen] = useState(false);


  
  const data = [
    { name: 'Page A', uv: 4000, pv: 2400, amt: 2400 },
    { name: 'Page B', uv: 3000, pv: 1398, amt: 2210 },
    { name: 'Page C', uv: 2000, pv: 9800, amt: 2290 },
    { name: 'Page D', uv: 2780, pv: 3908, amt: 2000 },
    { name: 'Page E', uv: 1890, pv: 4800, amt: 2181 },
    { name: 'Page F', uv: 2390, pv: 3800, amt: 2500 },
    { name: 'Page G', uv: 3490, pv: 4300, amt: 2100 },
  ];

  useEffect(() => {
    // Fetch products from the API
    const fetchProducts = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/get-products');
        setProductCount(response.data.length); 
      } catch (err) {
        console.error('Failed to fetch products:', err);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="d-flex">
      <Sidebar />
      <div className='w-100'>
        <Header />
        <div className="container mt-4" style={{ fontFamily: 'Arial, sans-serif' }}>
          <h3>Pretty Women Admin Dashboard</h3>
          <div className="row mt-4">
            <DashboardCard title="PRODUCTS" count={productCount} icon={<BsFillArchiveFill style={{ cursor: 'pointer' }} />} backgroundColor="#d09691" />
            <DashboardCard title="CATEGORIES" count={categoryCount} icon={<BsFillGrid3X3GapFill style={{ cursor: 'pointer' }} />} backgroundColor="#e17a70" />
            <DashboardCard title="CUSTOMERS" count={customerCount} icon={<BsPeopleFill style={{ cursor: 'pointer' }} />} backgroundColor="#b36d62" />
            <DashboardCard title="ORDERS" count={alertCount} icon={<FiBox style={{ cursor: 'pointer' }} />} backgroundColor="#b42c2c" />
          </div>
          {/* <div className="row mt-5">
            <div className="col-lg-6">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="pv" fill="#b42c2c" />
                  <Bar dataKey="uv" fill="#e17a70" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <div className="col-lg-6">
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="pv" stroke="#d09691" />
                  <Line type="monotone" dataKey="uv" stroke="#b42c2c" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

const DashboardCard = ({ title, count, icon, backgroundColor }) => (
  <div className="col-md-3">
    <div className="card" style={{ backgroundColor }}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="card-title">{title}</h5>
          {icon}
        </div>
        <h1 className="mt-3">{count}</h1>
      </div>
    </div>
  </div>
);

export default Dashboard;


import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from 'react-facebook-login';
import 'bootstrap/dist/css/bootstrap.min.css';
import LandingHeader from '../LandingPage/LandingHeader';
import Footer from '../Footer/Footer';

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://sjpapi.prettywomen.in/API/v1/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('userData', JSON.stringify({ email, password }));
        alert('Login successful!');
        navigate('/');
      } else {
        alert(data.error || 'Invalid credentials. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      alert('Login failed. Please try again later.');
    }
  };



  // const handleGoogleLoginSuccess = async (response) => {
  //   try {
  //     const res = await fetch('https://sjpapi.prettywomen.in/API/v1/api/v1/auth/google', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ token: response.credential }),
  //     });

  //     const data = await res.json();

  //     if (res.ok) {
  //       localStorage.setItem('userData', JSON.stringify(data));
  //       alert('Login successful!');
  //       window.location.href = '/';
  //     } else {
  //       alert(data.error || 'Google login failed. Please try again.');
  //     }
  //   } catch (error) {
  //     console.error('Google login error:', error);
  //     alert('Google login failed. Please try again later.');
  //   }
  // };



  // const handleFacebookLoginSuccess = async (response) => {
  //   if (response.accessToken) {
  //     try {
  //       const res = await fetch('https://sjpapi.prettywomen.in/API/v1/api/auth/facebook', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({ token: response.accessToken }),
  //       });

  //       const data = await res.json();

  //       if (res.ok) {
  //         localStorage.setItem('userData', JSON.stringify(data));
  //         alert('Login successful!');
  //         navigate('/');
  //       } else {
  //         alert(data.error || 'Facebook login failed. Please try again.');
  //       }
  //     } catch (error) {
  //       console.error('Facebook login error:', error);
  //       alert('Facebook login failed. Please try again later.');
  //     }
  //   } else {
  //     alert('Failed to get Facebook access token.');
  //   }
  // };

  // const handleFacebookLoginError = (error) => {
  //   console.error('Facebook login error:', error);
  //   alert('Facebook login failed. Please try again later.');
  // };

  return (
    <>
      <LandingHeader />
      <div className='d-flex justify-content-center' style={{ fontFamily: 'Arial, sans-serif' }}>
        <div className='col-lg-4'>
          <div className='p-4'>
            <form onSubmit={handleLogin}>
              <h3 className='text-center mb-5'><strong>LOGIN</strong></h3>
              <div className='mb-3'>
                <input
                  type='email'
                  placeholder='Email'
                  style={{ border: '1px solid black', padding: '10px 90px' }}
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </div>
              <div className='mb-3'>
                <input
                  type='password'
                  placeholder='Password'
                  style={{ border: '1px solid black', padding: '10px 90px' }}
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
              </div>
              <Link to='/forgotpassword' className='ms-1' style={{ textDecoration: 'none' }}>
                Forgot Password?
              </Link>

              <Link to='/guestlogin' className='ms-3'>
                <a className='text-dark' style={{ borderRadius: '5px' }}>
                  Guest Login
                </a>
              </Link>
              <Link to='/signup' className='ms-3'>
                <a className='text-dark' style={{ borderRadius: '5px' }}>
                  Create account
                </a>
              </Link>

              <div className='gap-2 mt-2'>
                <button type='submit' className='btn btn-dark'style={{width:'83%'}}>
                  LOGIN
                </button>
              </div>
            </form>

             {/* <p className='text-center mt-3'>
              <GoogleLogin
                onSuccess={handleGoogleLoginSuccess}
                onError={error => console.error('Google login error:', error)}
                buttonText='Continue with Google'
                className='btn btn-outline-dark me-2'
              />
            </p>
            <p className='text-center mt-3'>
              <FacebookLogin
                appId="1003390944866975"
                autoLoad={false}
                fields="name,email,picture"
                callback={handleFacebookLoginSuccess}
                onError={handleFacebookLoginError}
                textButton="Continue with Facebook"
                cssClass="btn mb-3 btn-outline-dark"
              />
            </p>  */}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;

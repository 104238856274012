import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import Logor from '../images/Logop.png';
import { RiAccountCircleLine } from 'react-icons/ri';
import Dropdown from 'react-bootstrap/Dropdown';
import { BsCart3, BsHeart } from 'react-icons/bs';
import { IoSearchOutline } from "react-icons/io5";
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';


function LandingHeader({ setSearch }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const wishlistItems = useSelector(state => state.wishlist.wishlistItems);
  const totalWishlistItems = wishlistItems.length;
  const totalQuantity = useSelector(state => state.cart.totalQuantity);


  useEffect(() => {
    const token = localStorage.getItem('userData');
    setIsLoggedIn(!!token);
  }, []);


  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    console.log('searchQuery updated:', event.target.value);
  };

  const handleSearchSubmit = (event) => {
    console.log('searchQuery:', searchQuery);
    if (event.key === 'Enter') {
      navigate(`/searchresults?query=${encodeURIComponent(searchQuery)}`);
      setShowSearchModal(false);
    }
  };

  const handleLogin = () => {
    navigate('/myaccount');
  };


  const handleSearchModalOpen = () => setShowSearchModal(true);
  const handleSearchModalClose = () => setShowSearchModal(false);

  return (
    <>
      <Navbar expand="lg" style={{ fontFamily: 'Arial, sans-serif' }}>
        <Container>
          {/* Visible on lg and md screens */}
          <Navbar.Brand as={Link} to="/">
            <img src={Logor} className='me-auto d-none d-md-flex' style={{ height: '90px', width: '250px', borderRadius: '20px' }} alt="Logo" />
          </Navbar.Brand>
          <Nav className="me-auto d-none d-md-flex">
            <Nav.Link as={Link} to="/"><strong className='text-black'>Home</strong></Nav.Link>
            <Nav.Link as={Link} to="/about"><strong className='text-black'>About</strong></Nav.Link>
            <Nav.Link as={Link} to="/trend"><strong className='text-black'>Trending</strong></Nav.Link>
            <Nav.Link as={Link} to="/brace"><strong className='text-black'>Bracelets</strong></Nav.Link>
            <Nav.Link as={Link} to="/earrings"><strong className='text-black'>Earrings</strong></Nav.Link>
            <Nav.Link as={Link} to="/necklaces"><strong className='text-black'>Necklaces</strong></Nav.Link>
            <Nav.Link as={Link} to="/rings"><strong className='text-black'>Rings</strong></Nav.Link>
          </Nav>
          <Nav className="my-lg-0 d-none d-md-flex align-items-center" style={{ fontSize: '16px' }}>
            <div className="d-flex align-items-center">
              <IoSearchOutline size={25} onClick={handleSearchModalOpen} style={{ cursor: 'pointer' }} />
              <Modal show={showSearchModal} onHide={handleSearchModalClose}>
                <div className="d-flex">
                  <input
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    onKeyPress={handleSearchSubmit}
                  />
                </div>
              </Modal>
            </div>
            <Dropdown align="end" className="ms-3">
              <Dropdown.Toggle variant="link" id="account-dropdown" className="text-black d-flex align-items-center">
                <RiAccountCircleLine size={25} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {isLoggedIn ? (
                  <Dropdown.Item onClick={handleLogin}>MY ACCOUNT</Dropdown.Item>
                ) : (
                  <>
                    <Dropdown.Item as={Link} to="/login">Sign In</Dropdown.Item>
                    <Dropdown.Item as={Link} to="/adminlogin">Admin Login</Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
            <Nav.Link as={Link} to="/wishlist" className="position-relative ms-3">
              <BsHeart size={25} />
              {totalWishlistItems > 0 && (
                <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
                  {totalWishlistItems}
                </span>
              )}
            </Nav.Link>
            <Nav.Link as={Link} to="/cart" className="position-relative ms-3">
              <BsCart3 size={25} />
              {totalQuantity > 0 && (
                <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
                  {totalQuantity}
                </span>
              )}
            </Nav.Link>
          </Nav>
        </Container>

        {/* Visible on sm screens */}
        <Container className="d-md-none">
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link as={Link} to="/"><strong className='text-black'>Home</strong></Nav.Link>
              <Nav.Link as={Link} to="/about"><strong className='text-black'>About</strong></Nav.Link>
              <Nav.Link as={Link} to="/trend"><strong className='text-black'>Trending</strong></Nav.Link>
              <Nav.Link as={Link} to="/brace"><strong className='text-black'>Bracelets</strong></Nav.Link>
              <Nav.Link as={Link} to="/earrings"><strong className='text-black'>Earrings</strong></Nav.Link>
              <Nav.Link as={Link} to="/necklaces"><strong className='text-black'>Necklaces</strong></Nav.Link>
              <Nav.Link as={Link} to="/rings"><strong className='text-black'>Rings</strong></Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <img src={Logor} className='d-block mx-auto' style={{ height: '40px', width: '150px', borderRadius: '20px' }} alt="Logo" />
          <Nav className="d-flex flex-row align-items-center my-lg-0" style={{ fontSize: '16px' }}>
            <div className="d-flex align-items-center">
              <IoSearchOutline size={25} onClick={handleSearchModalOpen} style={{ cursor: 'pointer' }} />
              <Modal show={showSearchModal} onHide={handleSearchModalClose}>
                <Modal.Body>
                  <Form className="d-flex">
                    <Form.Control
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                      onKeyPress={handleSearchSubmit}
                    />
                  </Form>
                </Modal.Body>
              </Modal>
            </div>
            <Dropdown align="end" className="me-3 d-block d-md-none">
              <div id="account-dropdown" className="text-black">
                {isLoggedIn ? (
                  <Dropdown.Item onClick={handleLogin}><RiAccountCircleLine size={25} /></Dropdown.Item>
                ) : (
                  <Link to="/login">
                    <RiAccountCircleLine size={25} />
                  </Link>
                )}
              </div>
            </Dropdown>
            <div className="d-flex align-items-center">
              <Nav.Link as={Link} to="/wishlist" className="d-flex align-items-center me-3 position-relative">
                <BsHeart size={25} />
                {totalWishlistItems > 0 && (
                  <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
                    {totalWishlistItems}
                  </span>
                )}
              </Nav.Link>
              <Nav.Link as={Link} to="/cart" className="d-flex align-items-center position-relative">
                <BsCart3 size={25} />
                {totalQuantity > 0 && (
                  <span className="badge rounded-pill bg-dark position-absolute top-0 start-100 translate-middle">
                    {totalQuantity}
                  </span>
                )}
              </Nav.Link>
            </div>
          </Nav>
        </Container>
      </Navbar>
      {/* <hr style={{ color: 'lightslategray' }} /> */}
    </>
  );
}

export default LandingHeader;

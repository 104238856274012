
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { FaHeart, FaRupeeSign, FaTrashAlt } from 'react-icons/fa';
import { removeFromCart } from '../../Redux/cartActions';

const CartItem = ({ data}) => {
  const { id, pname, pprice, pimage, discount, quantity } = data;
  const dispatch = useDispatch();
  const [localQuantity, setLocalQuantity] = useState(quantity);

  
  useEffect(() => {
    setTotalPrice(calculateTotalPrice(localQuantity));
  }, [localQuantity]);

  const calculateTotalPrice = (quantity) => {
    const price = parseFloat(pprice);
    const discountValue = parseFloat(discount) || 0;
    if (discountValue) {
      return (price - (price * discountValue) / 100) * quantity;
    } else {
      return price * quantity;
    }
  };

  

  const [totalPrice, setTotalPrice] = useState(calculateTotalPrice(quantity));
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(null);

 


  const handleMinusQuantity = async () => {
    if (localQuantity > 1) {
      try {
        setIsUpdating(true);
        setError(null);
        const response = await axios.put(`https://sjpapi.prettywomen.in/API/v1/api/cart/${id}`, { quantity: localQuantity - 1 });
        console.log('Update Response:', response.data); // Log response data
        setLocalQuantity(localQuantity - 1);
         // Update parent component or global state
      } catch (err) {
        console.error('Error updating quantity:', err.response ? err.response.data : err.message);
        setError('Failed to update quantity');
      } finally {
        setIsUpdating(false);
      }
    }
  };

  const handlePlusQuantity = async () => {
    try {
      setIsUpdating(true);
      setError(null);
      const response = await axios.put(`https://sjpapi.prettywomen.in/API/v1/api/cart/${id}`, { quantity: localQuantity + 1 });
      console.log('Update Response:', response.data); // Log response data
      setLocalQuantity(localQuantity + 1);
     // Update parent component or global state
    } catch (err) {
      console.error('Error updating quantity:', err.response ? err.response.data : err.message);
      setError('Out of Stock');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleRemoveFromCart = async () => {
    try {
      setIsUpdating(true);
      setError(null);
      const response = await axios.delete(`https://sjpapi.prettywomen.in/API/v1/api/cart/${id}`);
      console.log('Remove Response:', response.data); // Log response data
    // Update parent component or global state
    window.location.reload();
    dispatch(removeFromCart(id)); // Update Redux state
    } catch (err) {
      console.error('Error removing item from cart:', err.response ? err.response.data : err.message);
      setError('Failed to remove item from cart');
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div style={{fontFamily:'Arial, sans-serif',fontSize:'13px'}} className='col-lg-8 shadow  p-1 m-2 d-flex flex-row position-relative'>
    <img src={pimage} alt="Product" style={{ borderRadius: '10px', objectFit: 'cover', width: '160px', height: 'auto', marginRight: '10px' }} />
    
    <div className='d-flex flex-column flex-grow-1'>
      <h6 className='mb-4 mt-3'>{pname}</h6>
      <p className='mb-0'>
        Price:
        {discount ? (
          <>
            <span style={{ color: 'green', fontWeight: 'bold' }}>
              <FaRupeeSign /> {Math.round(calculateTotalPrice(localQuantity) / localQuantity)}
            </span>
            <span
              style={{
                textDecoration: 'line-through',
                color: '#888',
                fontSize: '0.85rem',
                marginLeft: '5px',
              }}
            >
              <FaRupeeSign /> {Math.round(pprice)}
            </span>
            <span style={{ color: '#d1235b', fontSize: '0.85rem', marginLeft: '5px' }}>
              ({discount}% Off)
            </span>
          </>
        ) : (
          <span style={{ color: '#d1235b', fontWeight: 'bold' }}>
            <FaRupeeSign /> {Math.round(pprice)}
          </span>
        )}
      </p>
      <p>Total Price: <FaRupeeSign /> {Math.round(totalPrice)}</p>
  
      {error && <p className='text-danger'>{error}</p>}
      
      {/* Buttons for quantity and actions */}
      <div className='d-flex align-items-center mt-4'>
        <button className='btn btn-sm me-2'style={{borderColor:'lightgrey'}} onClick={() => handleRemoveFromCart(id.productId)}  disabled={isUpdating}> <FaTrashAlt /> Remove Item</button>
        <button className='btn btn-sm'style={{borderColor:'lightgrey'}}  disabled={isUpdating}> <FaHeart /> Move to Wishlist</button>
      </div>
  
      {/* Position buttons in the right corner inside shadow */}
      <div className='flex-column align-items-end position-absolute' style={{ top: '10px', right: '10px'}}>
        <button className='btn btn-sm mb-2'style={{borderColor:'lightgrey'}} onClick={handleMinusQuantity} disabled={isUpdating}>-</button>
        <span className='mx-2'>{localQuantity}</span>
        <button className='btn btn-sm mb-2'style={{borderColor:'lightgrey'}} onClick={handlePlusQuantity} disabled={isUpdating}>+</button>
      </div>
    </div>
  </div>
  
  
  

  );
};

export default CartItem;

import {CLEAR_CART, ADD_TO_CART, CHANGE_QUANTITY, REMOVE_FROM_CART } from "./cartActionTypes";

export const addToCart = (payload) => ({
  type: ADD_TO_CART,
  payload,
});

export const changeQuantity = (payload) => ({
  type: CHANGE_QUANTITY,
  payload,
});

export const removeFromCart = (productId) => {
  return {
    type: REMOVE_FROM_CART,
    payload: productId,
  };
};

export const clearCart = () => ({
  type: CLEAR_CART
});


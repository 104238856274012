import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Table, Tabs, Tab } from 'react-bootstrap';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Head/Header';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';


const refunds = [
  { id: 'RFD123', orderId: 'ORD123', amount: '$50', status: 'Approved' },
  { id: 'RFD124', orderId: 'ORD124', amount: '$75', status: 'Pending' }
];

const Account = () => {
  const [activeKey, setActiveKey] = useState('orders');
  const [showOrderModal, setShowOrderModal] = useState(false);
  const [showTransactionModal, setShowTransactionModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);

  // Function to handle showing the order modal
  const handleShowOrderModal = (order) => {
    setSelectedOrder(order);
    setShowOrderModal(true);
  };

  const handleCloseOrderModal = () => {
    setShowOrderModal(false);
    setSelectedOrder(null);
  };

  // Function to handle showing the transaction modal
  const handleShowTransactionModal = async (transactionId) => {
    setLoading(true);
    try {
      const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/transactions/details', {
        params: { orderId: transactionId } // Adjust the parameter as needed
      });
      const transaction = response.data.find(t => t.transactionId === transactionId);
      setSelectedTransaction(transaction);
      setShowTransactionModal(true);
    } catch (error) {
      console.error('Error fetching transaction details:', error);
    } finally {
      setLoading(false);
    }
  };

  console.log(transactions)

  const handleCloseTransactionModal = () => {
    setShowTransactionModal(false);
    setSelectedTransaction(null);
  };

  // Fetch transactions on component mount
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get('https://sjpapi.prettywomen.in/API/v1/api/transactions/details');
        setTransactions(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, []);

  console.log(transactions);
  return (
    <div className="d-flex">
      <Sidebar />
      <div className="w-100">
        <Header />
        <div className="container mt-4" style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
          <h4>Accountant</h4>
          <Tabs
            id="account-tabs"
            activeKey={activeKey}
            onSelect={(k) => setActiveKey(k)}
            className="mb-3"
          >
            <Tab eventKey="orders" title="Order Management">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Order ID</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((order) => (
                    <tr key={order.id}>
                      <td>{order.userId}</td>
                      <td>{order.orderId}</td>
                      <td>{(order.amount / 100 * 1).toFixed(2)}</td>
                      <td>
                        {order.transactionStatus.trim().toUpperCase() === 'SUCCESS' ? (
                          <FaCheckCircle style={{ color: 'green' }} />
                        ) : (
                          <FaTimesCircle style={{ color: 'red' }} />
                        )}
                      </td>
                      <td>
                        <Button className='btn-sm' onClick={() => handleShowOrderModal(order)}>View Details</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="transactions" title="Payment Transactions">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Payment Transaction ID</th>
                    <th>Order ID</th>
                    <th>Amount</th>
                    <th>Payment Mode</th>
                    <th>Date</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions.map((transaction) => (
                    <tr key={transaction.transactionId}>
                      <td>{transaction.userId}</td>
                      <td>{transaction.paymentTransactionId}</td>
                      <td>{transaction.orderId}</td>
                      <td>{(transaction.amount / 100 * 1).toFixed(2)}</td>
                      <td>{transaction.paymentMode}</td>
                      <td>{new Date(transaction.transactionCreatedAt).toLocaleString()}</td>
                      <td>
                        <Button className='btn-sm' onClick={() => handleShowTransactionModal(transaction.transactionId)}>View Details</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="refunds" title="Refund Management">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Refund ID</th>
                    <th>Order ID</th>
                    <th>Amount</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {refunds.map((refund) => (
                    <tr key={refund.id}>
                      <td>{refund.id}</td>
                      <td>{refund.orderId}</td>
                      <td>{refund.amount}</td>
                      <td>{refund.status}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
          </Tabs>
        </div>
      </div>

      {/* Modal for order details */}
      <Modal show={showOrderModal} onHide={handleCloseOrderModal} >
        <Modal.Header closeButton>
          <Modal.Title>Order Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
            {loading ? (
              <p>Loading details...</p>
            ) : (
              selectedOrder ? (
                <div>
                  <p><strong>User ID:</strong> {selectedOrder.userId}</p>
                  <p><strong>Order ID:</strong> {selectedOrder.orderId}</p>
                  <p><strong>Amount:</strong> {(selectedOrder.amount / 100 * 1).toFixed(2)}</p>
                  <p><strong>Status:</strong> {selectedOrder.transactionStatus}</p>
                  <p><strong>Product Details:</strong> {selectedOrder.productdetails}</p>
                  <ul><img src={selectedOrder.productImage} alt={selectedOrder.productName} style={{ width: '100px' }} /></ul>
                  <ul><strong>Name:</strong> {selectedOrder.productName}</ul>
                  <ul>Product Price: {selectedOrder.productPrice}</ul>


                </div>
              ) : (
                <p>No details available</p>
              )
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      {/* Modal for transaction details */}
      <Modal show={showTransactionModal} onHide={handleCloseTransactionModal}>
        <Modal.Header closeButton>
          <Modal.Title>Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
            {loading ? (
              <p>Loading details...</p>
            ) : (
              selectedTransaction ? (
                <div>
                  <p><strong>Transaction ID:</strong> {selectedTransaction.transactionId}</p>
                  <p><strong>Order ID:</strong> {selectedTransaction.orderId}</p>
                  <p><strong>User ID:</strong> {selectedTransaction.userId}</p>
                  <p><strong>Username:</strong> {selectedTransaction.username}</p>
                  <p><strong>Email:</strong> {selectedTransaction.email}</p>
                  <p><strong>Mobile:</strong> {selectedTransaction.userMobile}</p>
                  <p><strong>Product Name:</strong> {selectedTransaction.productName}</p>
                  <p><strong>Product Image:</strong> <img src={selectedTransaction.productImage} alt={selectedTransaction.productName} style={{ width: '100px' }} /></p>
                  <p><strong>Amount:</strong> {(selectedTransaction.amount / 100 * 1).toFixed(2)}</p>
                  <p><strong>Status:</strong> {selectedTransaction.transactionStatus}</p>
                  <p><strong>Payment Transaction ID:</strong> {selectedTransaction.paymentTransactionId}</p>
                  <p><strong>Created At:</strong> {new Date(selectedTransaction.transactionCreatedAt).toLocaleString()}</p>
                  <p><strong>Transaction Mobile Number:</strong> {selectedTransaction.transactionMobileNumber || 'N/A'}</p>
                  <p><strong>State:</strong> {selectedTransaction.transactionState}</p>
                  <p><strong>Payment Mode:</strong> {selectedTransaction.paymentMode}</p>
                  <p><strong>Product Price:</strong> {selectedTransaction.productPrice}</p>
                  <p><strong>Product Description:</strong> {selectedTransaction.stylecode}</p>
                </div>
              ) : (
                <p>No details available</p>
              )
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Account;

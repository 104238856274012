import React from 'react'
import LandingHeader from './LandingHeader'
import Footer from '../Footer/Footer'

const About = () => {
  return (
    <>
    <div className='mt-5'>
    <LandingHeader/>
    <div className='mt-5'style={{color:' #d47bb1'}}>
      ABOUT
      </div>
      </div>
    <Footer/>
    </>
  )
}

export default About
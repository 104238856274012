import React from 'react'
import Sidebar from '../Sidebar/Sidebar'
import Header from '../Head/Header'
import { FiBox } from 'react-icons/fi'

const AcDashboard = () => {
  return (
    <div className="d-flex">
      <Sidebar />
      <div className='w-100'>
        <Header />
    <div className="container mt-4" style={{ fontFamily: 'Nexa, sans-serif' }}>
          <h3>Pretty Women Account Dashboard</h3>
          <div className="row mt-4">
            <DashboardCard title="ORDERS"  icon={<FiBox style={{ cursor: 'pointer' }} />} backgroundColor="#b42c2c" />
          </div>
        </div>
        </div>
        </div>
  )
}

const DashboardCard = ({ title, count, icon, backgroundColor }) => (
    <div className="col-md-3">
      <div className="card" style={{ backgroundColor }}>
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="card-title">{title}</h5>
            {icon}
          </div>
          <h1 className="mt-3">{count}</h1>
        </div>
      </div>
    </div>
  );

export default AcDashboard

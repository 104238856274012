import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import Footer from '../Footer/Footer';
import axios from 'axios';
import './Checkout.css';
import { useNavigate } from 'react-router-dom';
import LandingHeader from '../LandingPage/LandingHeader';

const PaymentPage = () => {
  const [cartItems, setCartItems] = useState([]);
  const [productList, setProductList] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState(0);
  const [showAdditionalInput, setShowAdditionalInput] = useState(false);
  const [useSameAddress, setUseSameAddress] = useState(true);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);
  const [discountCode, setDiscountCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [payUrl, setPayUrl] = useState('');
  const [isPayConfirm, setIsPayConfirm] = useState(false);
  const [open, setOpen] = useState(false);

  const [billingAddress, setBillingAddress] = useState({
    address: '',
    houseNo: '',
    city: '',
    state: '',
    zip: '',
    whatsapp: '',
  });

  const [shippingAddress, setShippingAddress] = useState({
    address: '',
    houseNo: '',
    city: '',
    state: '',
    zip: '',
  });

  const navigate = useNavigate();
  const userEmail = localStorage.getItem('emailUser');

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userEmail) return;
      try {
        const response = await axios.get(`https://sjpapi.prettywomen.in/API/v1/api/users?email=${encodeURIComponent(userEmail)}`);
        setUserData(response.data[0] || null);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchUserData();
  }, [userEmail]);


  
  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const [cartResponse, productResponse] = await Promise.all([
          axios.get('https://sjpapi.prettywomen.in/API/v1/api/cart'),
          axios.get('https://sjpapi.prettywomen.in/API/v1/api/products'),
        ]);
        setCartItems(cartResponse.data);
        setProductList(productResponse.data);
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchCartItems();
  }, []);

  useEffect(() => {
    const calculateTotalPrice = () => {
      const price = cartItems.reduce((total, cartItem) => {
        const product = productList.find(p => p.id === cartItem.productId);
        if (product) {
          const offerPrice = product.pprice - (product.pprice * product.discount) / 100;
          return total + (offerPrice * cartItem.quantity);
        }
        return total;
      }, 0);
      setTotalPrice(price);
      setDiscountedPrice(price);
    };

    calculateTotalPrice();
  }, [cartItems, productList]);

  const handleRadioChange = (e) => {
    setShowAdditionalInput(e.target.value === 'no');
  };

  const handleUseSameAddressChange = (e) => {
    setUseSameAddress(e.target.value === 'yes');
  };

  const handleAddressChange = (type, e) => {
    const { name, value } = e.target;
    if (type === 'billing') {
      setBillingAddress(prev => ({ ...prev, [name]: value }));
    } else {
      setShippingAddress(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
  
    try {
      const addressToUpdate = useSameAddress ? billingAddress : shippingAddress;
  
      // Perform address update
      await axios.post('https://sjpapi.prettywomen.in/API/v1/api/update-addresses', {
        userId: userData.id,
        shippingAddress: addressToUpdate,
        billingAddress : addressToUpdate,
      });

      // Perform payment
      const res = await axios.post('https://sjpapi.prettywomen.in/API/v1/pay/v1/pay', {
        payText: discountedPrice,
        userId: userData.id,
        pId: cartItems.map(item => item.productId),
      });
  
      if (res.data.success) {
        setPayUrl(res.data.url);
        window.open(res.data.url);
        setIsPayConfirm(true);
        setOpen(true);
      } else {
        console.error('API Response Error:', res.data.message || 'Unknown error');
        alert('Something went wrong');
      }
    } catch (error) {
      console.error('API Request Error:', error);
      setOpen(false);
      alert('Payment Error');
    } finally {
      setLoading(false); // Reset loading state regardless of success or failure
    }
  };
  

  const handleApplyDiscount = () => {
    const discountAmount = discountCode === 'DISCOUNT10' ? 10 : 0;
    setDiscountedPrice(totalPrice - discountAmount);
  };

  return (
    <div>
      <LandingHeader />
      <Container className="my-4" style={{ fontFamily: 'Arial, sans-serif', fontSize: '13px' }}>
        <div className="row justify-content-center">
          <div className='col-md-7'>
            <div>
              {/* Billing Address Section */}
              <h5>Billing Address</h5>
              <Row>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <input
                    type="text"
                    disabled
                    style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                    value={userData ? userData.username : ''}
                  />
                </Form.Group>
                <Form.Group controlId="mobile">
                  <Form.Label>Mobile</Form.Label>
                  <input
                    type="text"
                    disabled
                    style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                    value={userData ? userData.mobile : ''}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="whatsapp">
                  <Form.Label>Is this your Whatsapp Number?</Form.Label>
                  <Col className='d-flex flex-row align-items-center'>
                    <Form.Check
                      type="radio"
                      id="yesOption"
                      label="Yes"
                      name="whatsapp"
                      value="yes"
                      onChange={handleRadioChange}
                    />
                    <Form.Check
                      type="radio"
                      id="noOption"
                      label="No"
                      name="whatsapp"
                      value="no"
                      onChange={handleRadioChange}
                      style={{ marginLeft: '8px' }}
                    />
                  </Col>
                </Form.Group>
              </Row>
              {showAdditionalInput && (
                <Form.Group controlId="whatsapp">
                  <Form.Label>Whatsapp Number</Form.Label>
                  <input
                    type="number"
                    name="whatsapp"
                    required
                    value={billingAddress.whatsapp}
                    style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                    placeholder="Enter whatsapp number"
                    onChange={(e) => handleAddressChange('billing', e)}
                  />
                </Form.Group>
              )}
              <Form.Group controlId="address">
                <Form.Label>Address</Form.Label>
                <input
                  type="text"
                  name="address"
                  placeholder="Enter address"
                  style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                  required
                  value={billingAddress.address}
                  onChange={(e) => handleAddressChange('billing', e)}
                />
              </Form.Group>
              <Form.Group controlId="houseNo">
                <Form.Label>House.No / Area / Street</Form.Label>
                <input
                  type="text"
                  name="houseNo"
                  placeholder="house.no area, street, nearby landmark"
                  style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                  required
                  value={billingAddress.houseNo}
                  onChange={(e) => handleAddressChange('billing', e)}
                />
              </Form.Group>
              <Row>
                <Form.Group as={Col} controlId="city">
                  <Form.Label>City</Form.Label>
                  <input
                    type="text"
                    name="city"
                    placeholder="Enter city"
                    style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                    required
                    value={billingAddress.city}
                    onChange={(e) => handleAddressChange('billing', e)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="state">
                  <Form.Label>State</Form.Label>
                  <input
                    type="text"
                    name="state"
                    placeholder="Enter state"
                    style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                    required
                    value={billingAddress.state}
                    onChange={(e) => handleAddressChange('billing', e)}
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="zip">
                  <Form.Label>Zip Code</Form.Label>
                  <input
                    type="text"
                    name="zip"
                    placeholder="Enter zip code"
                    style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                    required
                    value={billingAddress.zip}
                    onChange={(e) => handleAddressChange('billing', e)}
                  />
                </Form.Group>
              </Row>

              {/* Shipping Address Section */}
              <h5>Shipping Address</h5>
              <Form.Group controlId="useSameAddress">
                <Form.Label>Is the shipping address the same as billing address?</Form.Label>
                <Col className='d-flex flex-row align-items-center'>
                  <Form.Check
                    type="radio"
                    id="sameAddressYes"
                    label="Yes"
                    name="useSameAddress"
                    value="yes"
                    checked={useSameAddress}
                    onChange={handleUseSameAddressChange}
                  />
                  <Form.Check
                    type="radio"
                    id="sameAddressNo"
                    label="No"
                    name="useSameAddress"
                    value="no"
                    checked={!useSameAddress}
                    onChange={handleUseSameAddressChange}
                    style={{ marginLeft: '8px' }}
                  />
                </Col>
              </Form.Group>

              {!useSameAddress && (
                <>
                  <Form.Group controlId="shippingAddress">
                    <Form.Label>Address</Form.Label>
                    <input
                      type="text"
                      name="address"
                      placeholder="Enter shipping address"
                      style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                      required
                      value={shippingAddress.address}
                      onChange={(e) => handleAddressChange('shipping', e)}
                    />
                  </Form.Group>
                  <Form.Group controlId="shippingHouseNo">
                    <Form.Label>House.No / Area / Street</Form.Label>
                    <input
                      type="text"
                      name="houseNo"
                      placeholder="house.no area, street, nearby landmark"
                      required
                      value={shippingAddress.houseNo}
                      style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                      onChange={(e) => handleAddressChange('shipping', e)}
                    />
                  </Form.Group>
                  <Row>
                    <Form.Group as={Col} controlId="shippingCity">
                      <Form.Label>City</Form.Label>
                      <input
                        type="text"
                        name="city"
                        placeholder="Enter city"
                        required
                        value={shippingAddress.city}
                        style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                        onChange={(e) => handleAddressChange('shipping', e)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="shippingState">
                      <Form.Label>State</Form.Label>
                      <input
                        type="text"
                        name="state"
                        placeholder="Enter state"
                        required
                        value={shippingAddress.state}
                        style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                        onChange={(e) => handleAddressChange('shipping', e)}
                      />
                    </Form.Group>
                    <Form.Group as={Col} controlId="shippingZip">
                      <Form.Label>Zip Code</Form.Label>
                      <input
                        type="text"
                        name="zip"
                        placeholder="Enter zip code"
                        required
                        value={shippingAddress.zip}
                        style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                        onChange={(e) => handleAddressChange('shipping', e)}
                      />
                    </Form.Group>
                  </Row>
                </>
              )}
            </div>
          </div>
          <div className='col-md-5 scrollable-content'>
            {cartItems.map(cartItem => {
              const product = productList.find(p => p.id === cartItem.productId);
              if (!product) return null;

              const offerPrice = Math.round(product.pprice - (product.pprice * product.discount) / 100);
              return (
                <div key={cartItem.productId} className="mb-3">
                  <img src={product.pimage} alt={product.pname} style={{ width: '100px', height: 'auto' }} />
                  <p>{product.pname}</p>
                  <p>Quantity: {cartItem.quantity}</p>
                  <p>Price: Rs. {offerPrice}</p>
                </div>
              );
            })}

            <div className="discount-section mt-3">
              <input
                type="text"
                id="discountCode"
                style={{ border: '1px solid darkgrey', padding: '15px', width: '100%' }}
                placeholder="Discount code"
                value={discountCode}
                onChange={(e) => setDiscountCode(e.target.value)}
              />
              <button
                className="btn btn-dark mt-2"
                style={{ border: '1px solid darkgrey', padding: '15px', marginLeft: '12px', width: '95%' }}
                onClick={handleApplyDiscount}
              >
                Apply
              </button>
            </div>
            <div className="bg-light mt-2">
              <h4>Order Summary</h4>
              <p>Total Amount: Rs. {Math.round(discountedPrice)}</p>
            </div>
          </div>
        </div>
        <Button
          variant="dark btn-sm"
          onClick={handleSubmit}
          className="mt-3"
          style={{ border: '1px solid darkgrey', padding: '15px', width: '58%' }}
        >
          Pay Now
        </Button>
      </Container>
      <Footer />
    </div>
  );
};

export default PaymentPage;
